import { Button, Card, IconButton, Modal, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import AppBar from '@mui/material/AppBar';
import FlagLogo from '../../../images/logos/flag-logo.png';
import { HiMenu } from 'react-icons/hi';
import LazyLoadImageFromURL from '../LazyLoadImageFromURL';
import { MdLocalPhone } from 'react-icons/md';
import Toolbar from '@mui/material/Toolbar';
import fetchUrlFromCDN from '../../../services/CDNService';
import getLeadId from '../../../hooks/getLeadIdFromUrl';
import getThemeName from '../../../hooks/useThemeFromUrl';
import { observer } from 'mobx-react-lite';
import styled from '@emotion/styled';
import { styled as styledMui } from '@mui/material/styles';
import themes from '../../../themes';

const LogoContainer = styled.div`
  max-width: 200px;
  min-width: 150px;
  @media screen and (max-width: 290px) {
    max-width: 170px !important;
  }
`;

const MyToolbar = styledMui(Toolbar)`
  display: flex;
  justify-content: flex-end;
  flex:1;
  grid-gap: 10px;
  boxSizing: border-box;
  padding-left: 0px !important;
  padding-right: 0px !important;

  .small-screen{
    display:none;
  }

  @media screen and (max-width:600px){
    .large-screen{
      display:none;
    }

    .small-screen{
      display:inline-block;
    }
  }
`;

const ModalCard = styled.div`
  margin: 20px;
  padding: 20px;
  border-radius: 8px;
  background-color: white;
  max-width: 500px;
  width: 100%;
  text-align: center;

  h2 {
    color: #222c69;
    font-size: 20px;
  }

  p {
    color: #515151;
  }

  .buttons-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
  }
`;

const Container = styled.div`
  color: white;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 8px 20px;
`;

const NavWrapper = styled.div`
  flex: 1;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  grid-gap: 50px;
  padding: 10px 30px;
  @media screen and (max-width: 1000px) {
    grid-gap: 10px;
  }
  @media screen and (max-width: 600px) {
    padding: 10px 20px;
  }

  @media screen and (max-width: 345px) {
    padding: 11px 20px;
  }
`;

const BurgerWrapper = styled.div`
  display: none;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 600px) {
    display: flex;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
`;

function Header(props) {
  const [imageUrl, setImageUrl] = useState();
  const [callMeNowPopupOpen, setCallMeNowPopupOpen] = useState(false);
  const { isCarrierSpecific, carrierName } = props;

  useEffect(() => {
    const brand = getThemeName();
    fetchUrlFromCDN(brand)
      .then((resp) => {
        'logo' in resp && setImageUrl(resp.logo);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const handleCallMeNow = (type) => {
    const recordId = getLeadId();
    const brand = getThemeName();
    let demographicsUrl = `${process.env.REACT_APP_DEMOGRAPHICS_URL}?referredBy=callMeNow&leadBrand=${brand}`;
    let bookingsUrl = `${process.env.REACT_APP_BOOKINGS_URL}/m101?referredBy=callMeNow`;
    if (recordId) {
      demographicsUrl += `&recordId=${recordId}`;
      bookingsUrl += `&recordId=${recordId}`;
    }

    if (type === 'call') {
      window.location.href = demographicsUrl;
    } else if (type === 'event') {
      window.location.href = bookingsUrl;
    }
  };

  const handleCallMeNowPopupOpen = () => {
    setCallMeNowPopupOpen(!callMeNowPopupOpen);
  };

  return (
    <>
      <AppBar
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.10)',
          position: 'sticky',
          top: 0,
        }}
        elevation={0}
        color='inherit'
        style={{ zIndex: '1999' }}
      >
        {isCarrierSpecific && (
          <Container
            style={{
              backgroundColor:
                carrierName === 'Aetna'
                  ? '#7D3F98'
                  : carrierName === 'Devoted Health'
                  ? '#FF4F00'
                  : '#053870',
            }}
          >
            <Typography
              sx={{
                fontFamily: 'Inter, sans-serif',
                fontSize: '12px',
                fontWeight: '500',
              }}
            >
              {carrierName === 'medicareassociation'
                ? 'This website is run by Elite Insurance Partners. We are proud to have earned the trust of the American Association for Medicare Supplement Insurance.'
                : `This website is run by Elite Insurance Partners, licensed to sell ${carrierName}® Medicare products`}
            </Typography>
          </Container>
        )}
        <HeaderContainer>
          <BurgerWrapper>
            <IconButton
              color='primary'
              onClick={() => props.handleDrawerToggle()}
            >
              <HiMenu />
            </IconButton>
          </BurgerWrapper>
          <NavWrapper>
            <LogoContainer
              style={{
                maxWidth:
                  carrierName === 'medicareassociation' ? '300px' : '325px',
              }}
            >
              <LazyLoadImageFromURL
                imageUrl={imageUrl}
                fallbackSrc={FlagLogo}
                alt='logo'
                className={'logo'}
              />
            </LogoContainer>
            <MyToolbar>
              <Button
                variant='contained'
                color='primary'
                disableElevation
                sx={{
                  borderRadius: '100px',
                  textTransform: 'initial',
                  fontWeight: '600',
                }}
                className='large-screen'
                size='large'
                onClick={handleCallMeNowPopupOpen}
              >
                Book a Free Call
              </Button>

              <div className='small-screen'>
                <IconButton
                  size='medium'
                  sx={{
                    background: '#222C69',
                    color: '#fff',
                    ':hover': {
                      background: '#222C69',
                      color: '#fff',
                    },
                  }}
                  onClick={handleCallMeNowPopupOpen}
                >
                  <MdLocalPhone />
                </IconButton>
              </div>
            </MyToolbar>
          </NavWrapper>
        </HeaderContainer>
      </AppBar>
      <Modal
        open={callMeNowPopupOpen}
        onClose={handleCallMeNowPopupOpen}
        sx={{
          zIndex: 100000,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ModalCard>
          <h2>When Can We Talk?</h2>
          <p>
            You can to speak to an agent now or schedule an appointment at a
            time that works best for you.
          </p>
          <div className='buttons-wrapper'>
            <Button
              variant='contained'
              color='primary'
              disableElevation
              sx={{
                borderRadius: '100px',
                textTransform: 'initial',
                fontWeight: '600',
              }}
              size='large'
              onClick={() => {
                handleCallMeNow('call');
              }}
            >
              Call Me Now
            </Button>
            <Button
              variant='contained'
              color='secondary'
              disableElevation
              sx={{
                borderRadius: '100px',
                textTransform: 'initial',
                fontWeight: '600',
              }}
              size='large'
              onClick={() => {
                handleCallMeNow('event');
              }}
            >
              Schedule An Appointment
            </Button>
          </div>
        </ModalCard>
      </Modal>
    </>
  );
}

export default observer(Header);
